<template>
  <el-main>
    <div class="btn">
      <router-link to="/goods/addGoods"><el-button type="primary" size="small">+ 添加商品</el-button></router-link>
      <el-button size="small" @click="showImport = !0">导入商品</el-button>
    </div>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="商品名称：">
        <el-input size="small" v-model="content.goods_name" placeholder="请输入商品名称"></el-input>
      </el-form-item>
      <el-form-item label="商品编码：">
        <el-input size="small" v-model="content.goods_coding" placeholder="请输入商品编码"></el-input>
      </el-form-item>
      <el-form-item label="商品分类：">
        <el-select v-model="content.classify_id" filterable size="small" placeholder="请选择">
          <el-option v-for="item in goodsClass" :key="item.id" :label="item.classify_name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button size="small" @click="searchGoods" type="primary">搜索</el-button>
        <el-button size="small" class="boderBlue" @click="exportEx">导出</el-button>
        <el-button size="small" @click="searchClear" type="text">清除搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-tabs v-model="content.status" type="card" @tab-click="handleClick(content.status)">
      <el-tab-pane :name="item.status" v-for="(item, index) in goodsStatus" :key="index">
        <span slot="label">{{ item.title }}（{{ item.count }}）</span>
      </el-tab-pane>
    </el-tabs>
    <GoodsList ref="goodsList" :goodsClass="goodsClass.slice(1)" :content="content"></GoodsList>
    <el-dialog title="导入商品" :visible.sync="showImport" width="550px">
      <el-link type="primary" :href="templatePath">下载导入模板</el-link>
      <el-input class="upload" v-model="filePath">
        <el-button slot="append">
          上传文件
          <input type="file" ref="inputer" @change="getFile" />
        </el-button>
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showImport = !1">取 消</el-button>
        <el-button type="primary" @click="comfirmImport">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import GoodsList from './components/GoodsList';
import config from '@/util/config';
import _ from 'lodash';
export default {
  components: {
    GoodsList,
  },
  data() {
    return {
      templatePath: config.baseurl + '/' + 'Upload/import/商品导入模板.xlsx',
      goodsStatus: [],
      showImport: !1,
      content: {
        status: 0,
        goods_name: '',
        goods_coding: '',
        classify_id: '',
      },
      formData: '',
      filePath: '',
    };
  },
  computed: {
    goodsClass() {
      let goodsTypeList = this.$store.getters.goodsTypeList;
      goodsTypeList.unshift({
        id: 0,
        classify_name: '全部',
      });
      return goodsTypeList;
    },
  },
  created() {
    this.$store.dispatch('goods/getTypeList');
    this.getGoodsStatus();
  },
  methods: {
    // 导出数据成excel
    exportEx() {
      this.$axios.post(this.$api.goods.export).then((res) => {
        if (res.code == 0) {
          let path = config.baseurl + '/' + res.result;
          let a = document.createElement('a');
          a.href = path;
          a.id = 'download';
          document.body.appendChild(a);
          a.click();
          let aDom = document.getElementById('download');
          document.body.removeChild(aDom);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getFile() {
      let files = this.$refs.inputer.files[0];
      let formData = new FormData();
      formData.append('file', files);
      this.filePath = files.name;
      this.formData = formData;
    },
    comfirmImport() {
      if (!this.formData) return this.$message.warning('请先上传文件');
      this.$axios.uploadFile(this.$api.goods.import, this.formData).then((res) => {
        if (res.code == 0) {
          this.showImport = !1;
          this.$message.success('商品导入成功');
          this.$refs.goodsList.getGoodsList();
          this.getGoodsStatus();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleClick(val) {
      this.$refs.goodsList.page = 1;
      this.$refs.goodsList.rows = 10;
      this.$refs.goodsList.getGoodsList();
    },
    typeChange(val) {
      val.length - 1 ? (this.content.classify_id = val[1]) : (this.content.classify_id = val[0]);
    },
    getGoodsStatus() {
      this.$axios
        .post(this.$api.goods.goodsStatus, {
          goods_name: this.content.goods_name,
          goods_coding: this.content.goods_coding,
          classify_id: this.content.classify_id,
        })
        .then((res) => {
          if (res.code == 0) {
            this.goodsStatus = res.result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    searchGoods() {
      this.$refs.goodsList.getGoodsList();
      this.getGoodsStatus();
    },
    searchClear() {
      this.content.goods_name = '';
      this.content.goods_coding = '';
      this.content.classify_id = '';
      this.getGoodsStatus();
      this.$refs.goodsList.getGoodsList();
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  display: flex;
  flex-direction: column;
  .upload {
    margin-top: 20px;
    .el-button {
      position: relative;
      input {
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
      }
    }
  }
  .btn {
    .el-button {
      margin-right: 10px;
    }
  }
}
</style>
